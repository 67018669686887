<template>
  <div class="p-1">
    <CRow>
      <h4>Olá <strong>{{userAuth.user}}</strong></h4>
      <br>
      {{userAuth}}
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    userAuth() {
      return this.$store.state.userAuth;
    },
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
